<template>
  <div class="circle-menu">
    <circle-menu type="top" :btn="false" :number="4" circle :colors="[ '#49bc85', '#29d164', '#29d164', '#29d164', '#29d164']">
      <span slot="item_btn" style="cursor: pointer; display: block; width: 100%; height: 100%;" @click="toggleCircleMenu">
        <font-awesome-icon icon="fas fa-lg fa-headset" style="font-size: 30px; margin-top: 10px;" :style="{color: toggleCircleMenuFgColor } " />
      </span>
      <!--        <a slot="item_1" href="https://wa.me/message/KG6YDXT2Y7E4J1" style="display: block;">-->
      <!--          <font-awesome-icon icon="fab fa-lg fa-whatsapp" style="color: #fff; font-size: 30px; margin-top: 10px;" title="whatsapp" />-->
      <!--        </a>-->
      <el-popover slot="item_1" placement="left" trigger="hover" style="cursor: pointer; display: block;">
        <font-awesome-icon slot="reference" icon="fab fa-lg fa-whatsapp" style="color: #fff; font-size: 30px; margin-top: 10px;" title="whatsapp" />
        <div style="float: left">
          <span style="margin-left: 60px;">+18433609110</span><br>
          <img :src="whatsAppQrcode" width="220" height="220"><br>
          <span style="margin-left: 60px;"><el-link href="https://wa.me/message/ZCGY3AH3SNCSF1" type="primary">Chat Now</el-link></span>
        </div>
        <!-- <div style="float: right">
          <span style="margin-left: 60px;">+8615371475870</span><br>
          <img :src="whatsAppQrcode2" width="220" height="220"><br>
          <span style="margin-left: 60px;"><el-link href="https://wa.me/message/KG6YDXT2Y7E4J1" type="primary">Chat Now</el-link></span>
        </div> -->
      </el-popover>
      <el-popover slot="item_2" placement="left" trigger="hover" style="cursor: pointer; display: block;">
        <font-awesome-icon slot="reference" icon="fab fa-lg fa-weixin" style="color: #fff; font-size: 30px; margin-top: 10px;" title="wechat" />
        <div>
          <span style="margin-left: 60px;">o832club_ricky</span><br>
          <img :src="weixinQrcode" width="220" height="220">
        </div>
      </el-popover>
      <a slot="item_3" href="mailto:rebeccah@0832club.com" style="display: block;">
        <font-awesome-icon icon="fas fa-lg fa-envelope" style="color: #fff; font-size: 30px; margin-top: 10px;" title="email" />
      </a>
      <a slot="item_4" href="sms:+8618433609110" style="display: block;">
        <font-awesome-icon icon="fas fa-lg fa-comment" style="color: #fff; font-size: 30px; margin-top: 10px;" title="message" />
      </a>
    </circle-menu>
  </div>
</template>

<script>
import weixinQrcode from '@/assets/images/wechat.png'
import whatsAppQrcode from '@/assets/images/whatsapp.png'
import whatsAppQrcode2 from '@/assets/images/whatsapp2.png'
import CircleMenu from 'vue-circle-menu'

export default {
  name: 'CustomerService',
  components: { CircleMenu },
  data() {
    return {
      weixinQrcode: weixinQrcode,
      whatsAppQrcode: whatsAppQrcode,
      whatsAppQrcode2: whatsAppQrcode2,
      toggleCircleMenuFgColor: '#183153'
    }
  },
  methods: {
    toggleCircleMenu() {
      if (this.CircleMenuOpen) {
        this.CircleMenuOpen = false
        this.toggleCircleMenuFgColor = '#183153'
      } else {
        this.CircleMenuOpen = true
        this.toggleCircleMenuFgColor = '#fff'
      }
    }
  }
}
</script>

<style scoped>
.circle-menu {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 1000;
}
</style>
