<template>
  <div class="main-header-search">
    <el-input
      v-model="input"
      class="album-search-input"
      placeholder="Search"
      @keyup.enter.native="searchAlbum"
    >
      <i v-if="input != ''" slot="suffix" class="el-icon-close" style="margin-right: 2.2em;padding-top: 0.95em;" @click="clearSearchInput"></i>
      <i slot="suffix" class="el-input_icon el-icon-search album-search-icon" @click="searchAlbum" />
      <!-- <el-button slot="append" icon="el-icon-search" @click="searchAlbum" /> -->
    </el-input>
  </div>
</template>

<script>

import middleUtil from '../utils/middleUtil'
export default {
  data() {
    return {
      input: ''
    }
  },
  methods: {
    searchAlbum() {
      this.$emit('search', this.input)
    },
    clearSearchInput() {
      this.input = ''
    }
  },
  mounted() {
    const that = this
    middleUtil.$on('resetSearchInput', () => {
      that.input = ''
    })
  }
}
</script>

<style scoped>
.main-header-search {
  max-width: 720px;
  width: 100%;
  padding: 5px;
  text-align: right;
  line-height: 30px;
  height: 40px;
  margin-right: 30px;
  border-bottom: solid 1px #e6e6e6;
  background-color: rgb(243, 247, 247);
}

.main-header-search >>> .el-input__inner {
  border-radius: 20px;
  height: 35px;
  margin-top: 5px;
  width: 75%;
}

.album-search-input {
  width: 100%;
  margin-right: 30px;
  position: relative;
}

.album-search-input >>> input:focus {
  border-color: #49bc85;
}

.album-search-icon {
  position: absolute;
  cursor: pointer;
  top: 13px;
  right: 10px;
}
</style>
