<template>
  <AppMain />
</template>

<script>
import AppMain from './AppMain.vue'
export default {
  name: 'Layout',
  components: {
    AppMain
  }
}
</script>
