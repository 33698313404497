<template>
  <el-container>
    <el-header>
      <div class="header_logo-wrap">
        <div class="header_logo">
          <el-image style="width: 100%; height: 100%" :src="logo" />
          <!--          <span style="color: white; font-size: 18px;">Trendshow</span>-->
        </div>
        <div style="float: right">
          <el-popover slot="item_1" placement="left" trigger="hover" style="float: left; cursor: pointer; display: block;">
            <span slot="reference" style="font-size: 16px;">Scan for mobile</span>
            <div>
              <el-image style="width: 200px; height: 200px" :src="scanMobile" />
            </div>
          </el-popover>
        </div>
        <!--        <div style="float: right">-->
        <!--          <el-menu-->
        <!--            :default-active="$route.path"-->
        <!--            mode="horizontal"-->
        <!--            background-color="black"-->
        <!--            router-->
        <!--          >-->
        <!--            <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/617331839131074560?source=share" target="_blank" style="color: white">How to order</a> </el-menu-item>-->
        <!--            <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/694747588151459840?source=share" target="_blank" style="color: white">How to chose size</a> </el-menu-item>-->
        <!--            <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/694747631547744256?source=share" target="_blank" style="color: white">How to avoid tariffs</a> </el-menu-item>-->
        <!--            <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/694747728685744128?source=share" target="_blank" style="color: white">Serve promise</a> </el-menu-item>-->
        <!--            &lt;!&ndash;      <el-menu-item class="search-menu-item">&ndash;&gt;-->
        <!--            &lt;!&ndash;        <HeaderSeach @search="searchAlbum" />&ndash;&gt;-->

        <!--            &lt;!&ndash;      </el-menu-item>&ndash;&gt;-->
        <!--          </el-menu>-->
        <!--        </div>-->
      </div>
    </el-header>

    <el-main>

      <div class="main-main">
        <div class="main-main-content">
          <el-row>
            <el-col :span="12" style="height: 51px;border-bottom: solid 1px #e6e6e6; background-color: rgb(243, 247, 247);">
              <HeaderMenu />
            </el-col>
            <el-col :span="3"><div style="height: 50px;border-bottom: solid 1px #e6e6e6; background-color: rgb(243, 247, 247);">&nbsp;</div></el-col>
            <el-col :span="8">
              <HeaderSeach @search="searchAlbum" />
            </el-col>
            <el-col :span="1"><div style="height: 50px;border-bottom: solid 1px #e6e6e6; background-color: rgb(243, 247, 247);">&nbsp;</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="row-search-value">
                <!--              <el-link v-if="showSearchLink" :underline="false" @click="hideSearchLink">{{ searchValue }}<i class="el-icon-close"></i></el-link>-->
                <!--              <el-link v-for="(item, index) in searchLinks" :underline="false" :key="index" @click="hideSearchLink(item)">&nbsp;{{ item.name }}<i class="el-icon-close"></i></el-link>-->
              </div>
            </el-col>
          </el-row>
          <router-view ref="main" :key="$route.path" />
        </div>
      </div>
      <div class="main-footer" />
    </el-main>

    <el-footer />
  </el-container>
</template>

<script>
import logo from '../assets/logo.png'
import scanMobile from '../assets/images/san_mobile.png'
import HeaderSeach from './HeaderSearch.vue'
import HeaderMenu from './HeaderMenu.vue'
// import CategoryMenu from './CategoryMenu'
import middleUtil from '../utils/middleUtil'
import Vue from 'vue'

export default {
  name: 'AppMain',
  components: {
    // CategoryMenu,
    HeaderSeach,
    HeaderMenu
  },
  data() {
    return {
      logo: logo,
      scanMobile: scanMobile,
      searchValue: '',
      showSearchLink: false,
      searchLinks: []
    }
  },
  mounted() {
    middleUtil.$on('selectBrandOrCategory', (val) => {
      if (val.id === 0) {
        this.searchLinks.forEach((item, index) => {
          if (item.type === val.type) {
            this.searchLinks.splice(index, 1)
          }
        })
        return
      }
      if (this.searchLinks.length === 0) {
        this.searchLinks.push(val)
        return
      }
      if (this.searchLinks.length === 1) {
        const v1 = this.searchLinks[0]
        if (v1.type === val.type) {
          this.searchLinks[0] = val
          Vue.set(this.searchLinks, 0, val)
        } else {
          this.searchLinks.push(val)
        }
        return
      }
      if (this.searchLinks.length === 2) {
        this.searchLinks.forEach((element, index) => {
          if (element.type === val.type) {
            Vue.set(this.searchLinks, index, val)
          }
        })
      }
    })
  },
  created() {
    const brandId = this.$route.query.brandId
    const categoryId = this.$route.query.categoryId
    const brandName = this.$route.query.brandName
    const categoryName = this.$route.query.categoryName
    if (brandId && brandName) {
      this.searchLinks.push({ id: brandId, name: brandName, type: 'brand' })
    }
    if (categoryId && categoryName) {
      this.searchLinks.push({ id: categoryId, name: categoryName, type: 'category' })
    }
  },
  methods: {
    searchAlbum(input) {
      this.searchValue = input
      if (this.searchValue) {
        this.showSearchLink = true
      } else {
        this.showSearchLink = false
      }
      this.$refs.main.searchAlbum(input)
    },
    hideSearchLink(val) {
      // this.showSearchLink = false
      // this.searchAlbum('')
      // middleUtil.$emit('resetSearchInput')
      this.searchLinks.forEach((item, index) => {
        if (item.type === val.type) {
          this.searchLinks.splice(index, 1)
        }
      })
      middleUtil.$emit('resetSearchLink', val)
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

body > .el-container {
  margin-bottom: 40px;
}

button {
  padding: 0;
}
.search-menu-item {
  margin-right: 20px;
  width: 30%;
  float: right;
}
.scan-mobile {
  float: left;
}
.el-header,
.el-footer {
  /*background-color: black;*/
  background-color: #49bc85;
  color: #fff;
  text-align: left;
  line-height: 60px;
}

.el-main {
  padding: 0;
}

.main-header,
.main-footer {
  background-color: #f3f7f7;
}

.header_logo-wrap {
  max-width: 1440px;
 height: 60px;
  width: 100%;
  margin: 0 auto;
}

.header_logo {
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-left: 10px;
  float: left;
}

.main-main {
  background-color: #fff;
}

.main-main-content {
  max-width: 1440px;
  min-height: 720px;
  width: 100%;
  margin: 20px auto;
  margin-top: 0;
}

.main-footer {
  text-align: center;
}

a:active,
a:hover,
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}

.el-pager li.active {
  background-color: #49bc85 !important;
}

.el-pager li:not(.active):hover {
  color: #49bc85 !important;
}
.row-search-value {
  height: 5px;
}
.main-header-menu {
  max-width: 1440px;
  width: 100%;
  /*margin: 0 auto;*/
  height: 40px;
}

/*.home-menu-item {*/
/*  font-size: 18px;*/
/*  color: white;*/
/*}*/
</style>
