<template>
  <el-dialog :title="title" :visible.sync="visible" style="text-align: center;">
    <video :src="src" controls width="100%" height="100%" />
  </el-dialog>
</template>

<script>
import { getVideo } from '../api/album'
export default {
  data() {
    return {
      videoHost: process.env.NODE_ENV === 'development' ? '' : 'https://go.jetlifefashion.com/',
      // videoHost: '',
      visible: false,
      albumImageId: 0,
      title: '',
      src: ''
    }
  },
  methods: {
    openDialog(albumImageId) {
      this.visible = true
      this.albumImageId = albumImageId
      this.getVideo()
    },
    getVideo() {
      getVideo({ albumImageId: this.albumImageId }).then(result => {
        if (result.code === 0) {
          this.title = result.data.VideoName
          this.src = this.videoHost + result.data.videoPath
        } else {
          this.$message.error(result.message)
        }
      })
    }
  }
}
</script>
