import request from './../utils/request'

export function getAlbumList(params) {
  return request({
    url: 'api/album/getList',
    method: 'get',
    params
  })
}

export function searchAlbum(params) {
  return request({
    url: 'api/album/search',
    method: 'get',
    params
  })
}

export function getAlbum(params) {
  return request({
    url: 'api/album/get',
    method: 'get',
    params
  })
}

export function getImageList(params) {
  return request({
    url: 'api/album/getImageList',
    method: 'get',
    params
  })
}

export function searchImage(params) {
  return request({
    url: 'api/album/searchImage',
    method: 'get',
    params
  })
}

export function getPlatformList(params) {
  return request({
    url: 'api/album/getPlatformList',
    method: 'get',
    params
  })
}

export function addComment(data) {
  return request({
    url: 'api/album/addComment',
    method: 'post',
    data
  })
}

export function getCommentList(params) {
  return request({
    url: 'api/album/getCommentList',
    method: 'get',
    params
  })
}

export function getVideo(params) {
  return request({
    url: 'api/album/getVideo',
    method: 'get',
    params
  })
}
