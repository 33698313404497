var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"album-wrap"},[_c('div',[(!_vm.showBrand)?_c('div',[_c('span',{staticStyle:{"cursor":"pointer","font-size":"24px","margin-right":"5px"}},[_c('i',{staticClass:"el-icon-s-unfold",on:{"click":_vm.toggleShowBrand}})])]):_vm._e(),(_vm.showBrand)?_c('div',{staticClass:"brand-wrap"},[_c('div',{staticClass:"brand-item brand-all-item"},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('div',[_c('el-input',{staticClass:"brand-search-input",attrs:{"placeholder":"Brands"},on:{"input":_vm.searchBrandFn},model:{value:(_vm.brandInput),callback:function ($$v) {_vm.brandInput=$$v},expression:"brandInput"}},[(_vm.brandInput != '')?_c('i',{staticClass:"el-icon-close",staticStyle:{"margin-right":"55px","padding-top":"0.95em"},attrs:{"slot":"suffix"},on:{"click":_vm.clearSearchInput},slot:"suffix"}):_vm._e(),_c('i',{staticClass:"el-input_icon el-icon-search album-search-icon",attrs:{"slot":"suffix"},on:{"click":_vm.searchAlbum},slot:"suffix"})])],1)])],1)],1),_c('div',{staticClass:"brand-item brand-all-item"},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('div',[_c('span',{class:{'brand-item-active': _vm.currentSelectedBrandIds.length == 0}},[_c('router-link',{attrs:{"to":{
                    path: '/',
                    query: {
                      pageIndex: _vm.pageIndex,
                      pageSize: _vm.pageSize,
                      brandIds: 0,
                      categoryIds: _vm.currentSelectedCategoryIds
                    }
                  }},nativeOn:{"click":function($event){return _vm.selectBrand({id: 0,name: 'ALL Brands', type: 'brand'})}}},[_vm._v(" All ")])],1),_c('span',{staticStyle:{"cursor":"pointer","font-size":"24px","margin-right":"5px","float":"right"}},[_c('i',{staticClass:"el-icon-s-fold",on:{"click":_vm.toggleShowBrand}})])])])],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},_vm._l((_vm.searchBrandOptions),function(item){return _c('span',{key:item.id,attrs:{"to":{},"title":item.name}},[_c('div',{staticClass:"brand-item",class:{'brand-item-active': _vm.currentSelectedBrandIds.indexOf(item.id) > -1},on:{"click":function($event){return _vm.selectBrand(item)}}},[_c('span',[_vm._v(_vm._s(item.name))]),(item.top)?_c('img',{attrs:{"src":_vm.hotCorner}}):_vm._e()])])}),0)],1)],1):_vm._e()]),_c('div',{staticClass:"album-main-wrap"},[_c('ul',{staticClass:"category-wrap"},[_c('li',{staticClass:"category-item category-all-item",class:{ 'category-item-active': _vm.currentSelectedCategoryIds.length == 0}},[_c('router-link',{attrs:{"to":{
            path: '/',
            query: {
              pageIndex: _vm.pageIndex,
              pageSize: _vm.pageSize,
              brandIds: _vm.currentSelectedBrandIds,
              categoryIds: []
            }
          },"underline":false},nativeOn:{"click":function($event){return _vm.selectCategory({id: 0, name: 'ALL Categories', type: 'category'})}}},[_vm._v(" All ")])],1),_vm._l((_vm.categoryOptions),function(item){return _c('li',{key:item.id,staticClass:"category-item",class:{'category-item-active': _vm.currentSelectedCategoryIds.indexOf(item.id) > -1}},[_c('span',{on:{"click":function($event){return _vm.selectCategory(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])})],2),_c('el-row',[_c('el-col',{attrs:{"span":16}},[_c('div',{staticClass:"row-search-value"},_vm._l((_vm.searchLinks),function(item,index){return _c('el-link',{key:index,attrs:{"underline":false},on:{"click":function($event){return _vm.hideSearchLink(item)}}},[_vm._v(" "+_vm._s(item.name)),_c('i',{staticClass:"el-icon-close"})])}),1)]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"header-pagination"},[_c('pagination',{attrs:{"total":_vm.total,"page":_vm.pageIndex,"limit":_vm.pageSize,"layout":"total,prev,next"},on:{"update:page":function($event){_vm.pageIndex=$event},"update:limit":function($event){_vm.pageSize=$event},"pagination":_vm.getAlbumList}})],1)])],1),_c('el-row',{attrs:{"gutter":20}},_vm._l((_vm.albumList),function(item){return _c('el-col',{key:item.id,attrs:{"xs":6,"sm":4,"lg":_vm.lgNum}},[_c('div',{staticClass:"album-main"},[_c('router-link',{attrs:{"target":"_blank","to":{
              path: 'album/detail',
              query: { id: item.id }
            }}},[_c('img',{staticClass:"album-image",attrs:{"src":item.cover ? _vm.imageHost + item.cover : _vm.defaultCover}}),(item.top)?_c('div',{staticClass:"cover-top"},[_c('span',[_c('img',{attrs:{"src":_vm.hotCorner,"width":"60px","height":"auto"}})])]):_vm._e(),(item.new)?_c('div',{staticClass:"cover-new"},[_c('span',[_vm._v("NEW")])]):_vm._e()])],1),_c('div',{staticClass:"album-footer"},[_c('div',[_c('span',{staticClass:"album-price",attrs:{"title":_vm.combinePrice(item.cSymbol, item.cPrice, item.price)}},[_vm._v(_vm._s(_vm.combinePrice(item.cSymbol, item.cPrice, item.price)))])]),_c('div',[_c('span',{staticClass:"album-title",attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])])])])}),1),_c('pagination',{attrs:{"total":_vm.total,"page":_vm.pageIndex,"limit":_vm.pageSize,"layout":"total,prev,pager,next,jumper"},on:{"update:page":function($event){_vm.pageIndex=$event},"update:limit":function($event){_vm.pageSize=$event},"pagination":_vm.getAlbumList}})],1),_c('customer-service',{ref:"CustomerService"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }