<template>
  <div class="main-header-menu">
    <el-menu
      v-if="device === 'desktop'"
      class="main-header_menu"
      :default-active="$route.path"
      mode="horizontal"
      background-color="#f3f7f7"
      active-text-color="#49bc85"
      style="flex-wrap: nowrap; display: flex"
      router
    >
      <el-menu-item index="/home" class="home-menu-item"><a href="#">Home</a></el-menu-item>
      <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/617331839131074560?source=share" target="_blank">How to order</a> </el-menu-item>
      <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/694747588151459840?source=share" target="_blank">How to chose size</a> </el-menu-item>
      <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/694747631547744256?source=share" target="_blank">How to avoid tariffs</a> </el-menu-item>
      <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/694747728685744128?source=share" target="_blank">Serve promise</a> </el-menu-item>
      <!--      <el-menu-item class="search-menu-item">-->
      <!--        <HeaderSeach @search="searchAlbum" />-->

      <!--      </el-menu-item>-->
    </el-menu>
    <scroll-view v-if="device === 'mobile'">
      <el-menu
        class="main-header_menu"
        :default-active="$route.path"
        mode="horizontal"
        background-color="#f3f7f7"
        active-text-color="#49bc85"
        style="flex-wrap: nowrap; display: flex"
        router
      >
        <el-menu-item index="/home" class="home-menu-item"><a href="#">Home</a></el-menu-item>
        <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/617331839131074560?source=share" target="_blank">How to order</a> </el-menu-item>
        <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/694747588151459840?source=share" target="_blank">How to chose size</a> </el-menu-item>
        <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/694747631547744256?source=share" target="_blank">How to avoid tariffs</a> </el-menu-item>
        <el-menu-item class="home-menu-item"><a href="https://www.tumblr.com/blog/view/0832club/694747728685744128?source=share" target="_blank">Serve promise</a> </el-menu-item>
        <!--      <el-menu-item class="search-menu-item">-->
        <!--        <HeaderSeach @search="searchAlbum" />-->

        <!--      </el-menu-item>-->
      </el-menu>
    </scroll-view>
    <!--    <el-dropdown-->
    <!--      v-if="device === 'mobile'"-->
    <!--      class="el-dropdown-link">-->
    <!--        <span>-->
    <!--        Home <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
    <!--    </span>-->
    <!--      <el-dropdown-menu>-->
    <!--        <el-dropdown-item><a href="https://www.tumblr.com/blog/view/0832club/617331839131074560?source=share" target="_blank">How to order</a></el-dropdown-item>-->
    <!--        <el-dropdown-item><a href="https://www.tumblr.com/blog/view/0832club/694747588151459840?source=share" target="_blank">How to chose size</a></el-dropdown-item>-->
    <!--        <el-dropdown-item><a href="https://www.tumblr.com/blog/view/0832club/694747631547744256?source=share" target="_blank">How to avoid tariffs</a></el-dropdown-item>-->
    <!--        <el-dropdown-item><a href="https://www.tumblr.com/blog/view/0832club/694747728685744128?source=share" target="_blank">Serve promise</a></el-dropdown-item>-->
    <!--      </el-dropdown-menu>-->
    <!--    </el-dropdown>-->
  </div>
</template>

<script>
import ScrollView from './scrollView'
export default {
  components: { ScrollView },
  data() {
    return {
      activeIndex: '1'
    }
  },
  computed: {
    device() {
      return this.$store.state.app.device
    }
  }
}
</script>

<style scoped>
.main-header-menu {
  max-width: 1440px;
  width: 100%;
  /*margin: 0 auto;*/
  height: 40px;
}

.home-menu-item {
  padding-top: 10px;
}

.main-header_menu >>> .el-menu-item {
  height: 50px;
  font-size: 15px;
  line-height: 30px;
  /* font-size: 18px; */
}

.main-header_menu .el-menu-item:hover{
  background: #d8e5e5 !important;
}

.el-dropdown-link {
  cursor: pointer;
  color: #49bc85;
  margin-left: 5px;
  padding-top: 1em;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
