<template>
  <div class="album-wrap">
    <div>
      <div v-if="!showBrand">
        <span style="cursor: pointer; font-size: 24px; margin-right: 5px;">
          <i class="el-icon-s-unfold" @click="toggleShowBrand" />
        </span>
      </div>
      <div v-if="showBrand" class="brand-wrap">
        <div class="brand-item brand-all-item">
          <el-row>
            <el-col :span="24">
              <div>
                <el-input
                  v-model="brandInput"
                  class="brand-search-input"
                  placeholder="Brands"
                  @input="searchBrandFn"
                >
                  <i v-if="brandInput != ''" slot="suffix" class="el-icon-close" style="margin-right: 55px;padding-top: 0.95em;" @click="clearSearchInput" />
                  <i slot="suffix" class="el-input_icon el-icon-search album-search-icon" @click="searchAlbum" />
                  <!-- <el-button slot="append" icon="el-icon-search" @click="searchAlbum" /> -->
                </el-input>
              </div>
            </el-col>
          </el-row>

        </div>
        <div class="brand-item brand-all-item">
          <el-row>
            <el-col :span="24">
              <div>
                <span :class="{'brand-item-active': currentSelectedBrandIds.length == 0}">
                  <router-link
                    :to="{
                      path: '/',
                      query: {
                        pageIndex: pageIndex,
                        pageSize: pageSize,
                        brandIds: 0,
                        categoryIds: currentSelectedCategoryIds
                      }
                    }"
                    @click.native="selectBrand({id: 0,name: 'ALL Brands', type: 'brand'})"
                  >
                    All
                  </router-link>
                </span>
                <span style="cursor: pointer; font-size: 24px; margin-right: 5px; float: right;">
                  <i class="el-icon-s-fold" @click="toggleShowBrand" />
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
        <!--        <div-->
        <!--          v-for="item in searchBrandOptions"-->
        <!--          :key="item.id"-->
        <!--          class="el-dropdown-menu__item"-->
        <!--          :class="{'brand-item-active': currentSelectedBrandId == item.id}"-->
        <!--        >-->
        <!--        <ul>-->
        <!--          <li-->
        <!--            v-for="item in searchBrandOptions"-->
        <!--            :key="item.id"-->
        <!--            class="el-dropdown-menu__item"-->
        <!--          >-->
        <el-row>
          <el-col :span="24">
            <!-- <router-link
              v-for="item in searchBrandOptions"
              :key="item.id"
              :to="{}"
              :title="item.name"
              @click.native="selectBrand(item)"
            > -->
            <span
              v-for="item in searchBrandOptions"
              :key="item.id"
              :to="{}"
              :title="item.name"
            >
              <div
                class="brand-item"
                @click="selectBrand(item)"
                :class="{'brand-item-active': currentSelectedBrandIds.indexOf(item.id) > -1}"
              >
                <span>{{ item.name }}</span>
                <img v-if="item.top" :src="hotCorner">
              </div>
            <!-- </router-link> -->
            </span>
          </el-col>
        </el-row>
        <!--          </li>-->
        <!--        </ul>-->
        <!--        </div>-->
      </div>

    </div>
    <div class="album-main-wrap">
      <ul class="category-wrap">
        <li class="category-item category-all-item" :class="{ 'category-item-active': currentSelectedCategoryIds.length == 0}">
          <router-link
            :to="{
              path: '/',
              query: {
                pageIndex: pageIndex,
                pageSize: pageSize,
                brandIds: currentSelectedBrandIds,
                categoryIds: []
              }
            }"
            :underline="false"
            @click.native="selectCategory({id: 0, name: 'ALL Categories', type: 'category'})"
          > All </router-link>
        </li>
        <li
          v-for="item in categoryOptions"
          :key="item.id"
          class="category-item"
          :class="{'category-item-active': currentSelectedCategoryIds.indexOf(item.id) > -1}"
        >
          <!-- <router-link
            :to="{}"
            :underline="false"
            @click.native="selectCategory(item)"
          >{{ item.name }}</router-link> -->
          <span @click="selectCategory(item)">
            {{ item.name }}
          </span>
        </li>
      </ul>
      <!--      <div>-->
      <!--        <el-row>-->
      <!--          <el-col :span="24">-->
      <!--            <el-row>-->
      <!--              <el-col :offset="1">-->
      <!--                <span style="font-size: 20px; font-weight: bolder;">Best Sellers</span>-->
      <!--              </el-col>-->
      <!--            </el-row>-->
      <!--            <SwipeBox :speed="2" :direction="'left'" :img-list="imgList" />-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--        <el-row>-->
      <!--          <el-col :span="24">-->
      <!--            <el-row>-->
      <!--              <el-col :offset="1">-->
      <!--                <span style="font-size: 20px; font-weight: bolder;">New Arrivals</span>-->
      <!--              </el-col>-->
      <!--            </el-row>-->
      <!--            <SwipeBox2 :speed="2" :direction="'left'" :img-list="imgList" />-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--      </div>-->
      <el-row>
        <el-col :span="16">
          <div class="row-search-value">
            <!--              <el-link v-if="showSearchLink" :underline="false" @click="hideSearchLink">{{ searchValue }}<i class="el-icon-close"></i></el-link>-->
            <el-link v-for="(item, index) in searchLinks" :key="index" :underline="false" @click="hideSearchLink(item)">&nbsp;{{ item.name }}<i class="el-icon-close" /></el-link>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="header-pagination">
            <pagination
              :total="total"
              :page.sync="pageIndex"
              :limit.sync="pageSize"
              layout="total,prev,next"
              @pagination="getAlbumList"
            />
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          v-for="item in albumList"
          :key="item.id"
          :xs="6"
          :sm="4"
          :lg="lgNum"
        >
          <div class="album-main">
            <router-link
              target="_blank"
              :to="{
                path: 'album/detail',
                query: { id: item.id }
              }"
            >
              <img
                class="album-image"
                :src="item.cover ? imageHost + item.cover : defaultCover"
              >
              <div v-if="item.top" class="cover-top"><span><img :src="hotCorner" width="60px" height="auto"></span></div>
              <!-- <div v-if="item.new" class="cover-new"><span><img :src="newCorner" width="60px" height="auto"></span></div> -->
              <div v-if="item.new" class="cover-new"><span>NEW</span></div>
            </router-link>
          </div>
          <div class="album-footer">
            <div>
              <span class="album-price" :title="combinePrice(item.cSymbol, item.cPrice, item.price)">{{ combinePrice(item.cSymbol, item.cPrice, item.price) }}</span>
            </div>
            <div>
              <span class="album-title" :title="item.name">{{ item.name }}</span>
            </div>
            <!--            <div>-->
            <!--              <span :title="collPrice(item.cSymbol, item.cPrice, item.price)">{{ collPrice(item.cSymbol, item.cPrice, item.price) }}</span>-->
            <!--            </div>-->
          </div>
        </el-col>
      </el-row>
      <pagination
        :total="total"
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        layout="total,prev,pager,next,jumper"
        @pagination="getAlbumList"
      />
    </div>
    <customer-service ref="CustomerService" />
  </div>

</template>

<script>
import { getAlbumList, searchAlbum } from '../api/album'
import { getBrandOptions } from '../api/brand'
import { getCategoryOptions } from '../api/category'
import Pagination from './Pagination.vue'
import 'element-ui/lib/theme-chalk/display.css'
import Cookies from 'js-cookie'

const defaultCover = require('../assets/images/default_cover.png')
import ResizeMixin from './mixin/ResizeHandler'
import middleUtil from '../utils/middleUtil'
// import SwipeBox from '../layout/SwipeBox'
// import SwipeBox2 from '../layout/SwipeBox2'
// import merge from 'webpack-merge'
import CustomerService from '@/components/CustomerService'
import router from '../router'
import hotCorner from '../assets/images/hot_corner.gif'
import newCorner from '../assets/images/new_corner.gif'
// import Vue from 'vue'
export default {
  name: 'Index',
  components: {
    CustomerService,
    // SwipeBox,
    // SwipeBox2,
    Pagination },
  mixins: [ResizeMixin],
  data() {
    return {
      searchLinks: [],
      imgList: [
        {
          src: require('../assets/sell/1.png'),
          name: 'Summer',
          price: '$12.00'
        },
        {
          src: require('../assets/sell/2.png'),
          name: 'Sneaker',
          price: '$23.00'
        },
        {
          src: require('../assets/sell/3.png'),
          name: 'Summer',
          price: '$45.00'
        },
        {
          src: require('../assets/sell/4.png'),
          name: 'Hat',
          price: '$32.00'
        },
        {
          src: require('../assets/sell/5.png'),
          name: 'Bag',
          price: '$10.00'
        }
      ],
      brandInput: '',
      total: 0,
      pageIndex: 1,
      pageSize: 96,
      imageHost: process.env.NODE_ENV === 'development' ? '' : 'https://go.jetlifefashion.com/',
      // imageHost: '',
      defaultCover: defaultCover,
      albumList: [],
      brandOptions: [],
      searchBrandOptions: [],
      categoryOptions: [],
      currentSelectedBrandIds: [],
      currentSelectedCategoryIds: [],
      showBrand: true,
      CircleMenuOpen: false,
      toggleCircleMenuFgColor: '#183153',
      currentCategoryName: '',
      currentBrandName: '',
      lgNum: 3,
      hotCorner: hotCorner,
      newCorner: newCorner
    }
  },
  computed: {
    device() {
      return this.$store.state.app.device
    },
    combinePrice() {
      return function(cSymbol, cPrice, price) {
        if (cPrice) {
          cPrice = cPrice.toFixed(2)
        }
        return cSymbol + cPrice + ' ≈ ￥' + price
      }
    },
    collPrice() {
      return function(cSymbol, cPrice, price) {
        if (cPrice) {
          cPrice = cPrice.toFixed(2)
        }
        return cSymbol + cPrice
      }
    }
  },
  created() {
    if (this.$route.query.brandIds) {
      if (Array.isArray(this.$route.query.brandIds)) {
        this.currentSelectedBrandIds = this.$route.query.brandIds
      } else {
        this.currentSelectedBrandIds = [this.$route.query.brandIds]
      }
    }

    if (this.$route.query.categoryIds) {
      if (Array.isArray(this.$route.query.categoryIds)) {
        this.currentSelectedCategoryIds = this.$route.query.categoryIds
      } else {
        this.currentSelectedCategoryIds = [this.$route.query.categoryIds]
      }
    }
    this.init()
  },

  mounted() {
    const that = this
    middleUtil.$on('exportData', () => {
      return that.exportData()
    })
    middleUtil.$on('selectAlbum', () => {
      that.getAlbumList()
    })
    middleUtil.$on('changeCategoryId', (val) => {
      that.currentSelectedCategoryId = val
    })
    middleUtil.$on('resetSearchLink', (val) => {
      this.getAlbumList()
    })
  },
  methods: {
    clearSearchInput() {
      this.brandInput = ''
      this.searchBrandFn()
    },
    exportData() {
      return { pageIndex: this.pageIndex, pageSize: this.pageSize, currentSelectedBrandIds: this.currentSelectedBrandIds }
    },
    init() {
      if (this.$route.query.p) {
        Cookies.set('p', this.$route.query.p)
      }

      this.getAlbumList()
      this.getBrandOptions()
      this.getCategoryOptions()
    },
    getAlbumList() {
      getAlbumList({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        brandIds: this.currentSelectedBrandIds,
        categoryIds: this.currentSelectedCategoryIds
      }).then(result => {
        if (result.code === 0) {
          if (result.data.list) {
            this.total = result.data.total
            this.albumList = result.data.list
          }
        } else {
          this.$message.error(result.message)
        }
      })
    },
    searchAlbum(input) {
      searchAlbum({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        input: input
      }).then(result => {
        if (result.code === 0) {
          if (result.data.list) {
            this.total = result.data.total
            this.albumList = result.data.list
          } else {
            this.$message.error(result.message)
          }
        }
      })
    },
    getBrandOptions() {
      getBrandOptions().then(result => {
        if (result.code === 0) {
          if (result.data.list) {
            this.brandOptions = result.data.list
            this.searchBrandOptions = result.data.list
          } else {
            this.$message.error(result.message)
          }
        }
      })
    },
    getCategoryOptions() {
      getCategoryOptions().then(result => {
        if (result.code === 0) {
          if (result.data.list) {
            this.categoryOptions = result.data.list
          } else {
            this.$message.error(result.message)
          }
        }
      })
    },
    selectBrand(item) {
      if (this.currentSelectedBrandIds.indexOf(item.id) === -1) {
        if (item.id === 0) {
          this.currentSelectedBrandIds = []
          this.searchLinks = this.searchLinks.filter((ele, index) => {
            return ele.type !== 'brand'
          })
        } else {
          this.currentSelectedBrandIds.push(item.id)
        }
        // middleUtil.$emit('selectBrandOrCategory', { id: item.id, name: item.name, type: 'brand' })
        this.getAlbumList()
        this.selectCateOrBrand({ id: item.id, name: item.name, type: 'brand' })

        router.push(
          {
            path: '/',
            query: {
              pageIndex: this.pageIndex,
              pageSize: this.pageSize,
              brandIds: this.currentSelectedBrandIds,
              categoryIds: this.currentSelectedCategoryIds
            }
          }
        )
      }
    },
    selectCategory(item) {
      if (this.currentSelectedCategoryIds.indexOf(item.id) === -1) {
        if (item.id === 0) {
          this.currentSelectedCategoryIds = []
          this.searchLinks = this.searchLinks.filter((ele, index) => {
            return ele.type !== 'category'
          })
        } else {
          this.currentSelectedCategoryIds.push(item.id)
        }
        // middleUtil.$emit('selectBrandOrCategory', { id: item.id, name: item.name, type: 'category' })
        this.getAlbumList()
        this.selectCateOrBrand({ id: item.id, name: item.name, type: 'category' })

        router.push({ path: '/', query: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          brandIds: this.currentSelectedBrandIds,
          categoryIds: this.currentSelectedCategoryIds
        }})
      }
    },
    selectCateOrBrand(val) {
      this.searchLinks.push(val)
    },
    toggleShowBrand() {
      if (this.showBrand) {
        this.showBrand = false
        if (this.device === 'desktop') {
          this.lgNum = 4
        }
      } else {
        this.showBrand = true
        if (this.device === 'desktop') {
          this.lgNum = 3
        }
      }
    },
    toggleCircleMenu() {
      if (this.CircleMenuOpen) {
        this.CircleMenuOpen = false
        this.toggleCircleMenuFgColor = '#183153'
      } else {
        this.CircleMenuOpen = true
        this.toggleCircleMenuFgColor = '#fff'
      }
    },
    searchBrandFn() {
      const b = this.brandInput
      this.searchBrandOptions = this.brandOptions.filter(function(val) {
        const searchField = { name: val.name }
        return Object.keys(searchField).some(function(key) {
          return String(val[key]).toLowerCase().indexOf(b.toLowerCase()) > -1
        })
      })
    },
    hideSearchLink(val) {
      // this.showSearchLink = false
      // this.searchAlbum('')
      // middleUtil.$emit('resetSearchInput')
      this.searchLinks = this.searchLinks.filter((item, index) => {
        return !(item.id === val.id && item.type === val.type)
      })

      if (val.type === 'brand') {
        this.currentSelectedBrandIds.splice(this.currentSelectedBrandIds.indexOf(val.id), 1)
      }

      if (val.type === 'category') {
        this.currentSelectedCategoryIds.splice(this.currentSelectedCategoryIds.indexOf(val.id), 1)
      }
      middleUtil.$emit('resetSearchLink', val)
    }
  }
}
</script>

<style scoped>
html, body{
  margin:0;
}
.album-wrap {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.brand-wrap {
  min-width: 300px;
  margin-right: 5px;
  border: 1px solid #ebebeb;
  background-color: #f3f7f7;
  max-height: 2500px;
  overflow-y: scroll;
}

/*.brand-wrap::-webkit-scrollbar {
  width: 3px;
}
.brand-wrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #a3a3a3;
}
.brand-wrap::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}*/

.brand-item {
  color: #545454;
  position: relative;
  border-top: 1px solid #ebebeb;
  padding-left: 16px;
  height: 48px;
  line-height: 48px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  cursor: pointer;
  outline: none;
}

.brand-all-item {
  font-size: 18px;
  text-align: center;
}

.brand-item a:hover {
  color: #49bc85;
}
.brand-item:hover {
  color: #49bc85;
}
.album-main-wrap {
  width: 100%
}
.category-wrap {
  background-color: #f3f7f7;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0.5em;
}
/* .category-wrap::-webkit-scrollbar {
    display: none;
}*/

.category-item {
  color: #545454;
  list-style:none;
  margin-right: 15px;
  line-height: 28px;
  font-size: 15px;
  cursor: pointer;
}

.category-all-item {
  font-size: 18px;
}

.category-item a:hover {
  color: #49bc85;
}

.brand-item-active {
  color: #49bc85;
}

.category-item-active {
  color: #49bc85;
}

.album-main {
  position: relative;
  margin-top: 10px;
  padding-bottom: 100%;
  height: 0;
}
.album-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.album-footer {
  padding: 5%;
  overflow: hidden;
  box-shadow: 0 1px 2px #9f9f9f,
            0 8px 0 -3px #fff,
            0 9px 1px -3px #9f9f9f;
}

.album-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display:inline-block;
  width:100%;
  font-size: 13px;
}

.album-price {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display:inline-block;
  width:100%;
  font-size: 13px;
  color: red;
}

.album-image-count {
  float: right;
  width: 25%;
}

.el-row {
  margin-bottom: 20px;
}

.el-col {
  margin-bottom: 10px;
}

.el-row :last-child {
  margin-bottom: 0;
}

a:active,
a:hover,
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}

.header-pagination .pagination-container {
  text-align: right;
  padding: 0;
  margin-top: 10px;
}

.brand-search-input {
  width: 100%;
  position: relative;
}

.brand-search-input >>> input:focus {
  border-color: #49bc85;
}
.brand-item >>> .el-input__inner {
  border-radius: 20px;
  height: 35px;
  margin-top: 2px;
  width: 75%;
}

.album-search-icon {
  position: absolute;
  cursor: pointer;
  top: 18px;
  right: 40px;
}

.cover-top {
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  /*border-bottom: 50px solid transparent;*/
  border-left: 40px solid transparent;
  position:absolute;
  right:10px;
  top:-10px;
}

.cover-top span {
  position:absolute;
  color:#fff;
  top: -30px;
  left: -30px;
  text-align: center;
  font-size: 12px;
  font-family: arial;
  transform: rotate(45deg);
  display:block;
}

.cover-new {
  width: 0;
  height: 0;
  /* border-top: 40px solid transparent; */
  border-top: 40px solid #49bc85;
  /*border-bottom: 50px solid transparent;*/
  border-right: 40px solid transparent;
  position:absolute;
  /* left: -10px; */
  /* top: -10px; */
    left: 0px;
  top: 0px;
}

.cover-new span {
  position:absolute;
  color:#fff;
  top: -30px;
  left: 0px;
  text-align: center;
  font-size: 12px;
  font-family: arial;
  transform: rotate(-45deg);
  display:block;
}

.row-search-value {
  margin-left: 20px;
  height: 30px;
}
</style>
