<template>
  <div class="album-wrap">
    <div class="album-header-wrap">
      <div class="album-header">
        <div class="album-header_cover">
          <img
            :src="imageHost + cover"
          >
        </div>
      </div>

      <div class="album-header_description">
        <span class="price-wrap">
          {{ cSymbol }}{{ cPrice }} ≈ ¥{{ price }}
        </span>
        <span
          class="album-header_albumtitle"
        >{{ name }}</span>
        <span><i class="album-header_verticalline" />{{ total }}</span>
        <div class="album-header_vendorlink">

          <div>
            <span class="slogan-warp">
              <a :href="orderNowLink" target="_blank">
                <el-button round style="background-color: #49bc85; color: #fff;width: 130px;height: 50px">
                  <span style="font-weight: bolder;font-size: 20px;">Buy now</span></el-button>
              </a>
            </span>
          </div>
          <div>
            <span>
              <span style="font-size: 16px;color: #49bc85;">{{ taobaoLink }}</span>
            </span>
          </div>
          <ul class="platform-wrap">
            <li class="platform-item">Orderlinks:</li>
            <li
              v-for="item in platformList"
              :key="item.id"
              class="platform-item"
            >
              <a v-if="item.link !== ''" :href="item.link" target="_blank"> {{ item.name }}</a>
            </li>
          </ul>
        </div>
        <ShareNetwork
          v-for="network in networks"
          :key="network.network"
          :network="network.network"
          :url="sharing.url"
          :title="sharing.title"
          :description="sharing.description"
        >
          <font-awesome-icon :icon="network.icon" style="font-size: 26px; color: gray; margin-right: 20px;" />
        </ShareNetwork>
      </div>
    </div>
    <div class="album-content">
      <template>
        <!--        <el-tabs v-model="activeName" type="card">-->
        <!--          <el-tab-pane label="Detail" name="detail">-->
        <el-row>
          <el-col v-if="device === 'desktop'" :span="6">
            <el-button-group>
              <el-button class="el-button-card" :class="{'change-button-active': showDetail, 'hover-card': hoverCard === 'Comments'}" @click="changeRadio1('Detail')" @mouseover.native="changeOtherBtn('Detail')">Detail</el-button>
              <el-button class="el-button-card" :class="{'change-button-active': showComments, 'hover-card': hoverCard === 'Detail'}" @click="changeRadio1('Comments')" @mouseover.native="changeOtherBtn('Comments')">Comments</el-button>
            </el-button-group>
          </el-col>
          <el-col v-if="device === 'mobile'" :span="24">
            <el-button-group>
              <el-button plain :class="{'change-button-active': showDetail}" @click="changeRadio1('Detail')">Detail</el-button>
              <el-button plain :class="{'change-button-active': showComments}" @click="changeRadio1('Comments')">Comments</el-button>
            </el-button-group>
          </el-col>
          <el-col v-if="showDetail && device === 'desktop'" :span="4" :offset="14">
            <el-divider direction="vertical" />
            <el-button type="text" :class="{'change-button-active2': lgNum === 3, 'default-btn-class': lgNum ===6}" @click="changePictureNum('Thumbnail')">Thumbnail</el-button>
            <el-divider direction="vertical" />
            <el-button type="text" :class="{'change-button-active2': lgNum === 6, 'default-btn-class': lgNum === 3}" @click="changePictureNum('Big')">Big</el-button>
            <el-divider direction="vertical" />
          </el-col>
        </el-row>
        <!--        <el-radio-group v-model="radio1" text-color="#49bc85" @input="changeRadio1" fill="#ffffff">-->
        <!--          <el-radio-button label="Detail" border></el-radio-button>-->
        <!--          <el-radio-button label="Comments" border></el-radio-button>-->
        <!--        </el-radio-group>-->
        <el-row v-if="showDetail" :gutter="10" style="margin-top: 10px;">
          <el-col
            v-for="item, index in imageList"
            :key="item.srcId"
            :xs="6"
            :sm="4"
            :lg="lgNum"
          >
            <div class="album-main">
              <el-image
                class="album-image"
                fit="cover"
                :src="imageHost + item.thumbPath"
                :preview-src-list="getSrcList(index)"
              />
              <i v-if="item.video" class="el-icon-video-play album-video" @click="openPlayerDialog(item.srcId)" />
            </div></el-col>
        </el-row>
        <Player v-if="showDetail" ref="playerDialog" />
        <pagination
          v-if="showDetail"
          :total="total"
          :page.sync="pageIndex"
          :limit.sync="pageSize"
          layout="total,prev,pager,next"
          @pagination="getImageList"
        />
        <!--          </el-tab-pane>-->
        <!--          <el-tab-pane label="Comments" name="comment">-->
        <div v-if="showComments" class="comments" style="margin-top: 10px;">
          <div class="post-comment" style="text-align: right;">
            <el-button type="text" style="color: #49bc85" @click="postComment">{{ addCommentText }}</el-button>
          </div>
          <div v-if="addCommentFormVisible" class="comment-form">
            <el-form ref="addCommentForm" label-position="top" size="mini" :rules="addCommentFormRules" :inline="true" :model="addCommentForm">
              <el-form-item label="Star" prop="score" style="width: 100%">
                <el-rate v-model="addCommentForm.score" />
              </el-form-item>
              <el-form-item label="Content" prop="content" style="width: calc(100% - 12px)">
                <el-input v-model="addCommentForm.content" type="textarea" rows="3" />
              </el-form-item>
              <el-form-item label="Nickname" prop="nickname" style="width: 30%;">
                <el-input v-model="addCommentForm.nickname" type="input" />
              </el-form-item>
              <el-form-item label="Email" prop="email" style="width: calc(70% - 24px);">
                <el-input v-model="addCommentForm.email" type="input" />
              </el-form-item>
              <el-form-item style="width: 100%;">
                <el-button type="primary" style="background: #49bc85;" @click="addComment">Send</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="comment-main">
            <div v-for="item in commentList" :key="item.id" class="comment" style="line-height: 16px;">
              <span style="color: #1e8cbe"><b>{{ item.nickname }}</b></span>
              <span style="margin-left: 10px;">
                <el-rate :value="item.score" disabled text-color="#ff9900" style="display: inline;" />
              </span>
              <span style="font-size: 12px; color:#6a737c;float:right"> {{ item.createdAt }} </span>

              <div style="margin: 10px 20px">
                <span><pre>{{ item.content }}</pre></span>
                <div v-if="item.reply" class="reply" style="border-top:#e1e8ee solid 1px; margin-top: 10px;word-wrap:break-word;">
                  <div style="margin-top: 10px">
                    <span><pre>{{ item.reply }}</pre></span>
                    <span style="color: #1e8cbe"><b> Admin </b></span>
                    <span style="font-size: 12px; color:#6a737c;"> – {{ item.updatedAt }} </span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <pagination
            :total="cTotal"
            :page.sync="cPageIndex"
            :limit.sync="cPageSize"
            layout="prev,next"
            @pagination="getCommentList"
          />
        </div>
        <!--          </el-tab-pane>-->
        <!--        </el-tabs>-->
      </template>
    </div>
    <customer-service ref="CustomerService" />
  </div>
</template>

<script>
import { getAlbum, addComment, getImageList, searchImage, getPlatformList, getCommentList } from '../api/album'
import 'element-ui/lib/theme-chalk/display.css'
import Pagination from './Pagination.vue'
import Player from './Player.vue'
import ResizeMixin from './mixin/ResizeHandler'
import CustomerService from '@/components/CustomerService'

export default {
  name: 'Album',
  components: { CustomerService, Pagination, Player },
  mixins: [ResizeMixin],
  data() {
    return {
      radio1: 'Detail',
      activeName: 'detail',
      pageIndex: 1, // image pagination
      pageSize: 96,
      total: 0,
      cPageIndex: 1, // comment pagination
      cPageSize: 10,
      cTotal: 0,
      orderNowLink: '#',
      taobaoLink: '',
      name: '',
      cover: '',
      addCommentText: 'Add a comment',
      addCommentFormVisible: false,
      imageHost: process.env.NODE_ENV === 'development' ? '' : 'https://go.jetlifefashion.com/',
      // imageHost: '',
      srcList: [],
      price: 0.0,
      cPrice: 0.0,
      cSymbol: '',
      imageList: [],
      platformList: [],
      commentList: [],
      addCommentForm: {
        albumId: this.$route.query.id,
        score: 5,
        nickname: '',
        email: '',
        content: ''
      },
      addCommentFormRules: {
        content: { required: true, message: 'This field is required' },
        nickname: { required: true, message: 'This field is required' },
        email: { required: true, message: 'This field is required', type: 'email' }
      },
      sharing: {
        url: window.location.href,
        title: 'share',
        description: ''
      },
      showDetail: true,
      showComments: false,
      lgNum: 3,
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'fab fa-lg fa-facebook' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fa-lg fa-twitter' },
        { network: 'vk', name: 'Vk', icon: 'fab fa-lg fa-vk' },
        { network: 'reddit', name: 'Reddit', icon: 'fab fa-lg fa-reddit' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fa-lg fa-whatsapp' }
      ],
      hoverCard: ''
    }
  },
  computed: {
    device() {
      return this.$store.state.app.device
    }
  },
  created() {
    console.log(this.imageHost)
    this.getAlbum()
    this.getPlatformList()
    this.getImageList()
    this.getCommentList()
  },
  methods: {
    changeOtherBtn(val) {
      this.hoverCard = val
    },
    changePictureNum(val) {
      if (val === 'Thumbnail') {
        this.lgNum = 3
      }
      if (val === 'Big') {
        this.lgNum = 6
      }
    },
    changeRadio1(val) {
      if (val === 'Detail') {
        this.showDetail = true
        this.showComments = false
      }
      if (val === 'Comments') {
        this.showDetail = false
        this.showComments = true
      }
    },
    getAlbum() {
      getAlbum({ id: this.$route.query.id }).then(result => {
        if (result.code === 0) {
          this.name = result.data.name
          this.cover = result.data.cover
          this.price = parseInt(result.data.price)
          this.cSymbol = result.data.cSymbol
          this.cPrice = result.data.cPrice.toFixed(2)
        } else {
          this.$message.error(result.message)
        }
      })
    },
    getPlatformList() {
      getPlatformList({ albumId: this.$route.query.id }).then(result => {
        if (result.code === 0) {
          this.platformList = result.data.list
          this.platformList.forEach(item => {
            if (item.buynow === 1) {
              this.orderNowLink = item.link
            }
            if (item.name === 'taobao') {
              this.taobaoLink = item.link
            }
          })
        } else {
          this.$message.error(result.data.$message)
        }
      })
    },
    getImageList() {
      getImageList({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        albumId: this.$route.query.id
      }).then(result => {
        this.imageList = result.data.list
        this.total = result.data.total
        this.srcList = []
        this.imageList.forEach((v) => {
          this.srcList.push(this.imageHost + v.srcPath)
        })
      })
    },
    searchAlbum(input) {
      searchImage({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        albumId: this.$route.query.id,
        input: input
      }).then(result => {
        if (result.code === 0) {
          this.imageList = result.data.list
          this.total = result.data.total
          this.srcList = []
          this.imageList.forEach((v) => {
            this.srcList.push(this.imageHost + v.srcPath)
          })
        }
      })
    },
    addComment() {
      this.$refs.addCommentForm.validate(valid => {
        if (valid) {
          this.addCommentForm.albumId = parseInt(this.addCommentForm.albumId)
          addComment(this.addCommentForm).then(result => {
            this.addCommentFormVisible = false
            this.addCommentText = 'Add a comment'
            if (result.code === 0) {
              this.$notify.success(result.message)
              this.getCommentList()
            } else {
              this.$notify.error(result.message)
            }
          })
        } else {
          return false
        }
      })
    },
    getCommentList() {
      getCommentList({
        pageIndex: this.cPageIndex,
        pageSize: this.cPageSize,
        albumId: this.$route.query.id
      }).then(result => {
        if (result.code === 0) {
          this.commentList = result.data.list
          this.cTotal = result.data.total
        } else {
          this.$message.error(result.data.$message)
        }
      })
    },
    postComment() {
      if (!this.addCommentFormVisible) {
        this.addCommentFormVisible = true
        this.addCommentText = 'cancel'
      } else {
        this.addCommentFormVisible = false
        this.addCommentText = 'Add a comment'
        if (this.$refs.addCommentForm) {
          this.$refs.addCommentForm.resetFields()
        }
      }
    },
    getSrcList(index) {
      return this.srcList.slice(index).concat(this.srcList.slice(0, index))
    },
    openPlayerDialog(albumImageId) {
      this.$refs.playerDialog.openDialog(albumImageId)
    }
  }
}
</script>

<style scoped>
.album-wrap {
  padding: 0 20px;
}

.album-header-wrap {
  display: flex;
}

.album-header {
  width: 20%;
}

.album-header_cover {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
}

.album-header_cover img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.album-header_albumtitle {
  font-size: 1.125em;
  line-height: 2;
}

.album-header_description {
  margin-left: 20px; width: 75%;
}

.album-header_verticalline {
  border-left: 1px solid #000;
  margin: 0 5px;
}

.album-content {
  margin-top: 20px;
}

.album-main {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
}
.album-main:hover .album-video {
  display: block;
}

.album-video {
  display: none;
  opacity: .5;
  color: #58c0ea;
  background-color: #fff;
  position: absolute;
  bottom: 40%;
  left: 50%;
  font-size: 2.5em;
  border-radius: 100%;
  transform: translateX(-50%);
}

.album-video:hover {
  cursor: pointer;
  opacity: 1;
}

.album-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.el-row {
  margin-bottom: 20px;
}

.el-col {
  margin-bottom: 10px;
}

.el-row :last-child {
  margin-bottom: 0;
}

.label {
  text-transform: none;
  font-size: 1.37em;
  margin-bottom: 1.5em;
  display: inline-block;
}

.label:before {
  content: "";
  display: inline-block;
  height: 1em;
  vertical-align: -0.13em;
  width: 4px;
  margin-right: 0.45em;
  background: #49bc85;
}

.el-rate__icon {
  margin: 0;
}

.comment-form {
  margin-bottom: 1rem;
}

.comment {
  padding-top: 1rem;
  border-top :1px solid #c2c0d6
}

.label:before {
  content: "";
  display: inline-block;
  height: 1em;
  vertical-align: -0.13em;
  width: 4px;
  margin-right: 0.45em;
  background: #49bc85;
}

.price-wrap {
  color: red;
  font-size: 2.25em;
}

.album-content >>> .el-tabs__item.is-active {
  color: #49bc85 !important;
}

.album-content >>> .el-tabs__item:hover {
  color: #49bc85;
}

.comment-main {
  border-bottom:1px solid #c2c0d6
}

.comment-form >>> textarea:focus {
  border-color: #49bc85;
}

.comment-form >>> input:focus {
  border-color: #49bc85;
}

.platform-wrap {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.platform-item {
  list-style:none;
  margin-right: 10px;
  font-size: x-large;
}

.comment-main .el-rate {
  line-height: 16px;
}

.comment-form >>> .el-rate__icon {
  margin-right: 0;
}

.comment-main >>> .el-rate__icon {
  margin-right: 0;
}

.change-button-active {
  color: #49bc85;
  border: 1px solid #d7dae2;
  border-bottom: 1px solid transparent;
  background-color: white;
}
.change-button-active2 {
  color: #49bc85;
  background-color: white;
}
.el-button-card:hover {
  color: #49bc85;
  background-color: white;
  border: 1px solid #d7dae2;
  border-bottom: 1px solid transparent;
}

.default-btn-class {
  color: black;
}

.hover-card {
  /*border-bottom: 1px solid #d7dae2;*/
}
</style>
