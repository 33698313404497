import Vue from 'vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'

import VueSocialSharing from 'vue-social-sharing'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebook, faGooglePlus, faReddit, faTwitter, faVk, faWeixin, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faHeadset, faComment } from '@fortawesome/free-solid-svg-icons'
library.add(faFacebook, faWeixin, faVk, faGooglePlus, faTwitter, faReddit, faWhatsapp, faComment, faEnvelope, faHeadset)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(ElementUI, { locale })
Vue.use(VueSocialSharing)

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
